<template>
<Box>
  <Row :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'" class="clickable" v-for="(inspection, index) in inspectionList"
       :key="index + 'il'" v-on:click="showInspectionDetails(inspection)">
    <div class="rc-box-col-5 rc-text-value rc-text-small" v-on:click="showInspectionDetails(inspection)">
      {{ driverName(inspection) }}
    </div>
    <div class="rc-box-col-3 rc-text-value rc-text-small" v-on:click="showInspectionDetails(inspection)">
      {{ inspection.createdDate().displayAs_HH_MM_A() }}
    </div>
    <div class="rc-box-col-5 rc-text-value rc-text-small" v-on:click="showInspectionDetails(inspection)">
      {{ Contents.decode(inspection.vehicle().find().name()) }}
    </div>
    <div 
      :class="defectTypeColour(inspection.defectType())" 
      class="rc-box-col-2 rc-text-value-light rc-text-centre rc-text-middle rc-text-small"
      v-on:click="showInspectionDetails(inspection)">
        {{ inspection.defectTypeDisplay() }}
    </div>
  </Row>
</Box>
</template>

<script>

import { mapGetters } from 'vuex';

import InspectionMap from "@/domain/model/inspection/InspectionMap.js";
import Timesheet from "@/domain/model/timesheet/Timesheet.js";

import ContentUtils  from '@/utils/ContentUtils.js';
import ConstUtils  from '@/utils/ConstUtils.js';
import StringUtils from "@/utils/StringUtils.js";

import MC from "@/domain/session/MC.js";

import Row from '@/portals/shared/library/box/Row.vue';
import Box from '@/portals/shared/library/box/Box.vue';

export default {
  name: 'home-inspection-row',
  components: {
    Box, Row,
  },
  props: {
    inspectionData: { type: Object, default: null },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      Strings: StringUtils,
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
       'auth_connected',
       'domain',
     ]),
    inspectionList: function() {
      var inspectionMap = new InspectionMap(this.domain, this.inspectionData);
      return inspectionMap.sort();
    },
  },
  methods: {
    showInspectionDetails: function(inspection) {
      var params = {
          id: inspection.id(),
        };
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION.DETAILS,
        params: params,
      });
    },
    driverName: function(inspection) {
      var name = inspection.driver().find().user().find().fullName();
      if (inspection.driver().isNew()) {
        name = this.MC.Inspection.DriverNotSet.value();
      }
      return name;
    },
    defectTypeColour: function (result) {
      if (result == Timesheet.Values.Status.COMPLETED_OFF_DAY) {
        return 'rc-input-na';
      }
      if (result == 'None' || result == Timesheet.Values.Status.COMPLETE) {
        return 'rc-input-good';
      }
      if (result == 'Minor' || result == Timesheet.Values.Status.INCOMPLETE) {
        return 'rc-input-minor';
      }
      if (result == 'Major' || result == Timesheet.Values.Status.MISSING) {
        return 'rc-input-major';
      }
      if (result == Timesheet.Values.Status.INPROGRESS) {
        return 'rc-input-inprogress';
      }
      
      return 'rc-input-good';
    },
  }
}
</script>